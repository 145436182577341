<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('title.lokasiRekamMedik') }}</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h5>{{ modalData.patient.patientName }} <span class="text-muted"> - {{ modalData.patient.medicalNo }}</span></h5>
            </div>
          </div>
          <div class="kt-separator kt-separator--border-dashed kt-separator--space-sm"></div>
          <div class="row">
            <div class="col-12">
              <div class="form group">
                <label>{{ $t('title.lokasiRekamMedik') }}:</label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon2-maps"></i></span></div>
                  <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : showLoading }'>
                    <div class="typeahead">
                      <input
                        class="form-control"
                        id="typeahead_location"
                        type="text"
                        dir="ltr"
                        placeholder="Nama Lokasi"
                        v-model="location"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-sm btn-default btn-bold btn-upper ml-3"
                    @click="addLocation()"
                  >{{ $t('button.updateLokasi') }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-separator kt-separator--border-dashed kt-separator--space-sm"></div>

          <div
            class="kt-timeline"
            v-if="locationData"
          >
            <!--Begin::Item -->
            <label>{{ $t('title.riwayatLokasi') }}:</label>
            <div
              class="kt-timeline__item kt-timeline__item--brand"
              v-for="data in locationData"
              :key="data.id"
            >
              <div class="kt-timeline__item-section">
                <div class="kt-timeline__item-section-border">
                  <div class="kt-timeline__item-section-icon">
                    <i class="flaticon2-maps kt-font-brand"></i>
                  </div>
                </div>
                <span class="kt-timeline__item-datetime kt-label-font-color-3">
                  <h5>{{ data.mrlocation.name }}</h5>
                </span><br>
              </div>
              <span class="kt-timeline__item-text">
                {{ data.createdDate | formatDateTime }}
              </span>
            </div>
            <!--End::Item -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            @click="clearData()"
          >{{ $t("button.close") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const MRLocationRepository = RepositoryFactory.get("mrlocation");
const MRTrackingRepository = RepositoryFactory.get("mrtracking");
const PatientRepository = RepositoryFactory.get("patient");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {
      id: null,
      locationData: null,
      showLoading: false,
      location: null,
      mrTracking: {
        mrlocation: {
          id: null
        },
        patient: {
          id: null
        }
      }
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  filters: {
    formatDateTime: function (value) {
      return moment(value).format("dddd, DD MMMM YYYY | HH:mm:ss");
    }
  },
  methods: {
    updateData: function (reservation) {
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    typeahead: function () {
      var vx = this;

      var location = new Bloodhound({
        remote: {
          url: process.env.VUE_APP_API_URL + "/api/_search/mr-locations",
          prepare: function (query, settings) {
            vx.showLoading = true;
            settings.url =
              settings.url + "?query=name%3A*" + query + "*";
            settings.headers = {
              Authorization: "Bearer " + access_token,
              "cache-control": "no-cache"
            };

            return settings;
          }
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name")
      });

      $("#typeahead_location")
        .typeahead(
          {
            hint: true,
            highlight: true,
            minLength: 1
          },
          {
            name: "location",
            display: function (item) {
              return item.name;
            },
            source: location,
            templates: {
              suggestion: Handlebars.compile(
                "<div>{{name}}</div>"
              )
            }
          }
        )
        .bind("typeahead:render", function () {
          vx.showLoading = false;
        })
        .bind("typeahead:select", function ($e, data) {
          vx.location = data.name;
          vx.mrTracking.mrlocation.id = data.id;
        });
    },
    addLocation: function () {
      if (this.mrTracking.mrlocation.id) {
        this.createTracking();
      } else {
        this.createLocation();
      }
    },
    async createLocation() {
      var vx = this;

      var params = {
        name: this.location
      }

      MRLocationRepository.createLocation(params)
        .then(response => {
          vx.mrTracking.mrlocation.id = response.data.id;
          vx.createTracking();
        }).catch(error => {
        });
    },
    createTracking: function () {
      var vx = this;
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Lokasi Rekam Medik akan dipindahkan.",
        swalType: "info",
        onConfirmButton: function () {
          blockUI.blockModal();
          vx.moveMR();
        },
        onCancelButton: function () {
          blockUI.unblockModal();
        }
      });
    },
    async moveMR() {
      var vx = this;

      await MRTrackingRepository.createTracking(this.mrTracking)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.fetchData(resolve, reject);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Lokasi Rekam Medik berhasil dipindahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.mrTracking.mrlocation.id = null;
              }
            });
          });
        })
        .catch(error => {
        });
    },
    async fetchData(resolve, reject) {
      new Promise((resolve, reject) => {
        MRTrackingRepository.get({ "patientId.equals": this.modalData.patient.id, "sort": "createdDate,DESC", "size": 10, "page": 1 }, resolve, reject);
      })
        .then(response => {
          this.locationData = response.data;
          resolve(response.data);
        }).catch(error => {
        });
    }
  },
  mounted() {
    this.typeahead();
    this.fetchData();
    this.mrTracking.patient.id = this.modalData.patient.id;
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
